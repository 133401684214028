var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"scroll d-flex flex-column",attrs:{"min-height":"50vh"}},[_c('v-overlay',{attrs:{"absolute":"","opacity":0.4},model:{value:(_vm.pending_save),callback:function ($$v) {_vm.pending_save=$$v},expression:"pending_save"}},[_c('v-progress-circular',{attrs:{"size":"64","indeterminate":"","color":"primary"}})],1),_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("floor_manager.title.settings"))+" ")]),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)],1),_c('v-card-text',[(_vm.edit_pf.updated_by)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("floor_manager.label.last_update", _vm.edit_pf))+" ")]):_vm._e(),(_vm.dimensions)?_c('v-form',{ref:"dimension_card_form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-1"},[_c('v-col',[_c('v-text-field',{attrs:{"disabled":!_vm.isAbleToEdit,"outlined":"","dense":"","hide-details":"auto","rules":[
                v => (v ? true : 'Cannot save an empty name'),
                v =>
                  v?.length < 255
                    ? true
                    : 'Rule name support only 255 characters',
              ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IconTooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.name'),"description":_vm.$t('floor_manager.help.pf.name')}})]},proxy:true}],null,false,2024182322),model:{value:(_vm.edit_pf.name),callback:function ($$v) {_vm.$set(_vm.edit_pf, "name", $$v)},expression:"edit_pf.name"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{ref:"price_floor_input",attrs:{"disabled":!_vm.isAbleToEdit,"outlined":"","dense":"","hide-details":"auto","label":"Price floor","type":_vm.price_floor_focus ? 'number' : 'number',"rules":[
                v => (v ? true : 'Please fill a price floor before saving.'),
                v => (v > 0 ? true : 'A price floor should be greater than 0'),
              ]},on:{"focus":function($event){_vm.price_floor_focus = true},"blur":function($event){_vm.price_floor_focus = false}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('IconTooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.value'),"description":_vm.$t('floor_manager.help.pf.value')}})]},proxy:true}],null,false,540288530),model:{value:(_vm.price_floor),callback:function ($$v) {_vm.price_floor=$$v},expression:"price_floor"}})],1)],1),_c('v-row',[_c('v-expansion-panels',{attrs:{"disabled":!_vm.isAbleToEdit}},_vm._l((_vm.activeDimension),function(dimension){return _c('v-expansion-panel',{key:dimension.name},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[_c('strong',[_c('IconTooltip',{attrs:{"content":_vm.$t(
                            `floor_manager.label.dimension.${dimension.name.toLowerCase()}`
                          ),"description":_vm.$t(
                            `floor_manager.help.dimension.${dimension.name.toLowerCase()}`
                          )}})],1)]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.customLabel(dimension)))])])],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c(_vm.customElement(dimension.type?.component, { dimension }),_vm._b({ref:"dimension",refInFor:true,tag:"component",attrs:{"outlined":""},model:{value:(dimension.value),callback:function ($$v) {_vm.$set(dimension, "value", $$v)},expression:"dimension.value"}},'component',
                        _vm.customOptions(dimension.type?.options, { dimension })
                      ,false))],1)],1),_c('v-row',[(dimension.updated_by)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("floor_manager.label.last_update", _vm.edit_pf))+" ")]):_vm._e()])],1)],1)}),1)],1)],1):_c('div',[_vm._v(_vm._s(_vm.$t("floor_manager.label.no_select")))])],1),(_vm.isAbleToEdit)?_c('v-footer',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"disabled":!_vm.dimensions,"color":"primary","text":"","loading":_vm.pending_save},on:{"click":_vm.onSave}},[_vm._v(" Save ")]),(_vm.onCancel)?_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }