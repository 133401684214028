<template>
  <v-dialog :value="conflict.status" @input="handleClose" max-width="800">
    <v-card>
      <v-card-title class="headline">
        Conflict Resolution
      </v-card-title>

      <v-card-text>
        <p>Select which version you want to keep for each changed field:</p>
        <p v-if="!isAllSelected" class="warning--text mt-4">
          Please select a version for all changed fields
        </p>
        <v-simple-table v-if="diffData">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Field</th>
                <th colspan="2">Changes</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(diff, field, index) in diffData"
                :key="field"
                :class="{
                  'grey lighten-3': index % 2 === 0,
                }"
              >
                <td class="font-weight-medium">
                  {{
                    $t(`floor_manager.label.dimension.${field.toLowerCase()}`)
                  }}
                </td>
                <td
                  class="value-cell"
                  :class="getValueClass(field, 'server')"
                  @click="selectVersion(field, 'server')"
                >
                  <div class="d-flex align-center">
                    <v-radio
                      :off-icon="
                        selectedVersions[field] === 'server'
                          ? '$radioOn'
                          : '$radioOff'
                      "
                      class="mr-2"
                      readonly
                    />
                    <span>{{
                      formatValue({
                        value: diff.server,
                        field,
                        type: diff.type,
                      })
                    }}</span>
                  </div>
                  <div class="version-label" v-if="index === 0">
                    Server Version
                  </div>
                </td>
                <td
                  class="value-cell"
                  :class="getValueClass(field, 'local')"
                  @click="selectVersion(field, 'local')"
                >
                  <div class="d-flex align-center">
                    <v-radio
                      :off-icon="
                        selectedVersions[field] === 'local'
                          ? '$radioOn'
                          : '$radioOff'
                      "
                      class="mr-2"
                      readonly
                    />
                    <span>{{
                      formatValue({ value: diff.local, field, type: diff.type })
                    }}</span>
                  </div>
                  <div class="version-label" v-if="index === 0">
                    Your Version
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="handleClose">
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="handleMerge"
          :disabled="!isAllSelected"
        >
          Apply Selected Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ConflictDialog",

  data() {
    return {
      diffData: null,
      selectedVersions: {},
    };
  },

  computed: {
    ...mapGetters("floorRules", ["getConflict", "getEditPriceFloor"]),
    conflict() {
      return this.getConflict;
    },
    isAllSelected() {
      return Object.values(this.selectedVersions).every(v => v !== null);
    },
  },

  watch: {
    "conflict.status": {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.loadDiff();
        }
      },
    },
  },

  methods: {
    ...mapActions("floorRules", [
      "resolveConflict",
      "fetchFloorRules",
      "fetchServerVersion",
      "mergeAndSaveFloorRules",
    ]),

    async loadDiff() {
      const local = this.getEditPriceFloor;
      const server = await this.fetchServerVersion(local.id);

      this.diffData = this.computeDiff({ server, local });

      this.selectedVersions = Object.keys(this.diffData).reduce(
        (acc, field) => {
          acc[field] = null;
          return acc;
        },
        {}
      );
    },

    computeDiff({ server, local }) {
      const diff = {};
      const format = dimensions =>
        Object.assign(
          {},
          ...dimensions.map(dim => ({
            [dim.name]: { value: dim.value, type: dim.type },
          }))
        );

      const local_dimensions = format(local.dimensions);
      const server_dimensions = format(server.dimensions);
      for (const field of Object.keys(local_dimensions)) {
        const serverValue = server_dimensions[field].value;
        const localValue = local_dimensions[field].value;
        const changed =
          JSON.stringify(serverValue) !== JSON.stringify(localValue);

        if (changed) {
          diff[field] = {
            server: serverValue,
            local: localValue,
            changed,
            type: server_dimensions[field].type,
          };
        }
      }

      return diff;
    },

    selectVersion(field, version) {
      this.$set(this.selectedVersions, field, version);
    },

    getValueClass(field, version) {
      const selected = this.selectedVersions[field];
      if (selected === null) return "";
      return selected === version ? "green lighten-4" : "red lighten-4";
    },

    async handleMerge() {
      if (!this.isAllSelected) return;

      const mergedData = { ...this.getEditPriceFloor };
      for (const dim of mergedData.dimensions) {
        const selected_value = this.diffData[dim.name]?.[
          this.selectedVersions[dim.name]
        ];

        if (!selected_value) continue;
        dim.value = selected_value;
      }

      await this.mergeAndSaveFloorRules(mergedData);
      this.resolveConflict();
    },

    handleClose() {
      this.resolveConflict();
    },
    formatValue({ field, value, type }) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        return "<empty>";
      }

      if (field === "Country") {
        return value?.length < 15
          ? value.map(({ name }) => name).join(", ")
          : `${value.length} selected countries`;
      }

      const items = type?.options?.["items"];
      if (items && typeof items !== "string") {
        return type.options.items
          .filter(item => value.includes(item.v))
          .map(item => item.t)
          .join(", ");
      }

      if (Array.isArray(value)) {
        return value.join(", ");
      }

      return value;
    },
  },
};
</script>

<style scoped>
td:last-child {
  border-right: 0;
}

td {
  border-right: 1px solid grey;
}

.value-cell {
  cursor: pointer;
  padding: 12px !important;
  min-width: 200px;
  position: relative;
}

.value-cell:hover {
  opacity: 0.8;
}

.version-label {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.v-radio {
  margin-right: 8px;
}
</style>
