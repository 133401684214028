var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',{attrs:{"fixed-header":"","height":"63vh"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"clickable",on:{"click":function($event){return _vm.sortBy(_vm.status_sort, { name: 'status' })}}},[_vm._v(" "+_vm._s(_vm.$t("floor_manager.label.pf.status"))+" "),(
              _vm.price_sort.method_name === 'status' && _vm.price_sort.desc !== null
            )?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down")+" ")]):_vm._e()],1),_c('th',{staticClass:"clickable",on:{"click":function($event){return _vm.sortBy(_vm.name_sort, { name: 'name' })}}},[_c('IconTooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.name'),"description":_vm.$t('floor_manager.help.pf.name')}}),(_vm.price_sort.method_name === 'name' && _vm.price_sort.desc !== null)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down")+" ")]):_vm._e()],1),_c('th',{staticClass:"clickable",on:{"click":function($event){return _vm.sortBy(_vm.floor_sort, { name: 'floor' })}}},[_c('IconTooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.value'),"description":_vm.$t('floor_manager.help.pf.value')}}),(
              _vm.price_sort.method_name === 'floor' && _vm.price_sort.desc !== null
            )?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down")+" ")]):_vm._e()],1),_c('th',{staticClass:"clickable",on:{"click":function($event){return _vm.sortBy(_vm.target_sort)}}},[_c('IconTooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.target'),"description":_vm.$t('floor_manager.help.pf.target')}}),(
              _vm.price_sort.method_name === 'target' && _vm.price_sort.desc !== null
            )?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down")+" ")]):_vm._e()],1)])]),_c('tbody',_vm._l((_vm.prepared_price_floor),function(pf){return _c('tr',{key:pf.id,class:pf.id === _vm.active ? 'clicked_active' : '',on:{"click":() => _vm.setSelection(pf)}},[_c('td',[_c('v-switch',{attrs:{"disabled":!_vm.isAbleToEdit},on:{"click":function($event){return _vm.changeStatus(pf)}},model:{value:(pf.status),callback:function ($$v) {_vm.$set(pf, "status", $$v)},expression:"pf.status"}})],1),_c('td',[_c('a',{staticClass:"rule-name",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(_vm._s(_vm.priceFloorNameHandler(pf)))])]),_c('td',[_vm._v(" "+_vm._s((+pf.floor).toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" EUR ")]),_c('td',[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.gradiantDimension(_vm.configured_dimensions(pf))},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" mdi-target ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-0"},[_c('v-list',[_vm._l((_vm.configured_dimensions(pf)),function(dim,i){return _c('v-list-item',{key:i},[_vm._v(" "+_vm._s(dim.name)+" = "+_vm._s(_vm.targetValueHandler(dim))+" ")])}),(_vm.configured_dimensions(pf).length === 0)?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t("floor_manager.label.no_dimension"))+" ")]):_vm._e()],2)],1)],1)],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }