<template>
  <v-card height="100%" min-height="90vh">
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          {{ $t("nav_menu.floor_manager") }}
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="openCreatePriceFloor"
            v-if="isAbleToEdit"
            >New pricing rule</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            color="success"
            @click="onPush"
            :loading="pending_push"
            v-if="isAbleToEdit"
            >Push</v-btn
          >
        </v-col>
        <v-col cols="2" v-if="!isExtern">
          <v-select
            outlined
            hide-details
            :items="publishersList"
            item-text="name"
            item-value="publisher_code"
            v-model="publisher"
            return-object
            v-on:change="onPublisherChange"
          >
            <template v-slot:label>
              <IconTooltip
                :content="$t('floor_manager.label.publisher')"
                :description="$t('floor_manager.help.publisher')"
              />
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-combobox
            ref="search"
            outlined
            dense
            hide-details
            label="Search"
            placeholder="Looking for something..."
            style="width: 50%"
            append-icon=""
            v-model="filter.query"
            :items="filter.items"
            item-text="name"
            item-value="id"
            chips
            multiple
            small-chips
            deletable-chips
            clearable
            @change="onChange"
            @focus="hideCriteriaOpts"
            @keydown.tab="onTabAutocomplete"
          >
            <template v-slot:item="{ item, props }">
              <v-list-item v-bind="props" @click="showCriteriaOpts(item)">
                {{ item.name }}
              </v-list-item>
            </template>
          </v-combobox>
          <v-card
            style="width: 15%;position: absolute;z-index: 100"
            v-if="filter.open"
          >
            <v-card-title>
              <v-row>
                <v-col>
                  {{ filter.field }}
                </v-col>
                <v-col align="right">
                  <v-btn icon>
                    <v-icon @click="hideCriteriaOpts">close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="d-flex flex-column">
              <v-select
                hide-details
                :items="filter.operators"
                item-text="t"
                item-value="v"
                placeholder="Selectionner"
                v-model="filter.ops"
              />
              <v-text-field
                v-model="filter.value"
                @keydown.enter="applyFilter"
              />
              <v-btn
                text
                small
                color="primary"
                class="flex-row-reverse"
                @click="applyFilter"
                >Apply</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <div :style="{ width: `${dimension ? dividerPosition : 100}%` }">
          <FloorTable
            :price_floor="getPriceFloors"
            @select_price_floor="persistDimension"
            :name_filter="filter.pf_name_filter"
            :is-able-to-edit="isAbleToEdit"
          />
        </div>
        <div
          v-if="dimension"
          :class="{
            'resize-bar': true,
            disapear: dividerPosition > 80,
          }"
          @mousedown.prevent="startDragging"
          @mouseover.prevent
          :style="{
            left: `${dividerPosition}%`,
          }"
        />
        <div
          :class="{
            disapear: dividerPosition > 80,
          }"
          :style="{ width: ` ${99 - dividerPosition}%` }"
          v-if="dimension"
        >
          <DimensionCard
            :dimensions="sorted_dimensions"
            style="max-height: 70vh"
            :is-able-to-edit="isAbleToEdit"
          />
        </div>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-col class="d-flex flex-row-reverse">
        <template v-if="getLastConfigPushed">
          Last push: {{ getLastConfigPushed }} | Rules amount:
          {{ getPriceFloors.filter(pf => pf.status).length }}
        </template>
      </v-col>
    </v-card-actions>
    <v-dialog
      v-model="create_price_floor"
      @keydown.esc="cancelCreatePriceFloor"
      fullscreen
      transition="slide-x-reverse-transition"
    >
      <v-card class="d-flex flex-row-reverse opacity">
        <DimensionCard
          :dimensions="dimension"
          style="min-width: 70vw; max-width: 70vw"
          :on-cancel="cancelCreatePriceFloor"
          :on-post-save="closeCreatePriceFloor"
          :is-able-to-edit="isAbleToEdit"
        />
        <v-col @click="cancelCreatePriceFloor" />
      </v-card>
    </v-dialog>
    <ConflictDialog />
  </v-card>
</template>

<script>
import FloorTable from "@/components/Extern/FloorManagement/FloorTable.vue";
import DimensionCard from "@/components/Extern/FloorManagement/DimensionCard.vue";
import IconTooltip from "@/components/Extern/Helper/IconTooltip.vue";
import ConflictDialog from "@/components/Extern/FloorManagement/ConflictDialog.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import store from "@/store";
import { eventBus } from "@/main";

export default {
  name: "FloorManager",
  components: { IconTooltip, DimensionCard, FloorTable, ConflictDialog },
  props: {
    product: {
      type: Number,
      default: 1,
    },
  },
  emits: ["cancel_select_price_floor"],
  async mounted() {
    await store.dispatch("context/fetchContext");
    void store.dispatch("publishers/fetchAllCountries");
    store.commit("floorRules/setProduct", this.product);
    await store.dispatch("floorRules/fetchFloorPublisher");

    if (this.isExtern) {
      this.publisher = this.publishersList[0];
    }

    await this.initPriceFloorManagement({
      product: this.product,
      publisher_code: this.publisher?.publisher_code,
    });
    this.initial_state = window.structuredClone(this.getPriceFloors);
    const dimension_registry = this.getDimensions;
    dimension_registry.sort((a, b) => b.priority - a.priority);
    this.filter.items.push(...dimension_registry);
  },
  computed: {
    ...mapGetters("floorRules", [
      "getPriceFloors",
      "getPublisherList",
      "getDimensions",
      "getLastConfigPushed",
    ]),
    ...mapGetters("context", ["isUserExtern"]),
    isExtern() {
      return this.isUserExtern;
    },
    isAbleToEdit() {
      // todo uncomment the next predicate when open edition for extern people
      return !this.isExtern /*|| this.publisher.can_edit*/;
    },
    publishersList() {
      if (this.isExtern) {
        return [...this.getPublisherList];
      }
      return [this.general_publisher, ...this.getPublisherList];
    },
    sorted_dimensions() {
      if (this.dimension === null) {
        return null;
      }
      const cdim = this.dimension ?? [];
      return cdim.sort((a, b) => b.type_priority - a.type_priority);
    },
  },
  methods: {
    ...mapMutations("floorRules", ["setPriceFloorFilter", "setProduct"]),
    ...mapActions("floorRules", [
      "setPriceFloor",
      "setPublisher",
      "initPriceFloorManagement",
      "addPriceFloor",
      "removeLastPriceFloor",
      "selectPriceFloorToEdit",
      "pushFloors",
    ]),
    handleDragging(e) {
      const percentage = (e.pageX / window.innerWidth) * 100;
      if (percentage >= 40 && percentage <= 85) {
        this.dividerPosition = percentage.toFixed(2);
      }
    },
    startDragging() {
      document.addEventListener("mousemove", this.handleDragging);
      document.addEventListener("mouseup", this.endDragging);
    },
    endDragging() {
      if (this.dividerPosition >= 80) {
        eventBus.$emit("cancel_select_price_floor");
        this.dividerPosition = 50;
      }

      document.removeEventListener("mousemove", this.handleDragging);
      document.removeEventListener("mouseup", this.endDragging);
    },
    async onPublisherChange(publisher) {
      const publisher_code = publisher?.publisher_code || null;
      this.dimension = null;
      this.setPublisher(publisher_code);
      await store.dispatch("floorRules/fetchFloorDimensions");
      this.filter.items.splice(1, Infinity);
      const dimension_registry = this.getDimensions;
      dimension_registry.sort((a, b) => b.priority - a.priority);
      this.filter.items.push(...dimension_registry);
    },
    async onPush() {
      this.pending_push = true;
      await this.pushFloors();
      this.pending_push = false;
    },
    persistDimension(pf) {
      if (pf === null) {
        this.lock_selection = false;
        this.dimension = null;
        return;
      }
      this.lock_selection = true;
      this.dimension = pf.dimensions;
      this.selectPriceFloorToEdit(pf);
    },
    openCreatePriceFloor() {
      this.addPriceFloor();
      const price_floors = this.getPriceFloors;
      const last_added_pf = price_floors[price_floors.length - 1];
      this.persistDimension(last_added_pf);
      this.create_price_floor = true;
    },
    cancelCreatePriceFloor() {
      this.closeCreatePriceFloor();
      this.removeLastPriceFloor();
      this.dimension = null;
    },
    closeCreatePriceFloor() {
      this.create_price_floor = false;
      this.dimension = null;
      eventBus.$emit("cancel_select_price_floor");
    },
    showCriteriaOpts(item) {
      this.filter.field = item.name;
      this.filter.open = true;
      this.$refs.search?.blur?.();
      return item;
    },
    hideCriteriaOpts() {
      this.filter.open = false;
      this.filter.field = null;
      this.filter.ops = "contains#";
      this.filter.value = null;
    },
    async applyFilter() {
      this.filter.query.push(
        `${this.filter.field}:${this.filter.ops}${this.filter.value}`
      );
      await this.onChange();
      this.hideCriteriaOpts();
    },
    async onChange() {
      const last_term = this.filter.query[this.filter.query.length - 1];

      const onTabChange = () => {
        const result = this.getDimensions.filter(({ name }) =>
          name.toLowerCase().includes(last_term?.toLowerCase())
        );
        if (result.length === 0) return;
        this.filter.query.pop();
        if (this.filter.open) return;
        this.showCriteriaOpts(result[0]);
        this.filter.isTabChange = false;
      };

      if (this.filter.isTabChange || this.filter.open) {
        onTabChange();
      }

      if (this.filter.field?.includes(last_term)) {
        this.filter.query.pop();
      }
      this.setPriceFloorFilter(
        this.filter.query.filter(v => v.includes(":")).join(";")
      );
      await this.initPriceFloorManagement({
        product: this.product,
        publisher_code: this.publisher?.publisher_code,
      });

      this.filter.pf_name_filter ??= [];
      this.filter.pf_name_filter.splice(0, this.filter.pf_name_filter.length);
      this.filter.pf_name_filter.push(
        ...this.filter.query.filter(v => !v.includes(":"))
      );
    },
    async onTabAutocomplete() {
      this.filter.isTabChange = true;
    },
  },
  data() {
    const general_publisher = {
      name: "General",
      publisher_code: null,
    };
    return {
      dimension: null,
      dividerPosition: 50,
      general_publisher,
      publisher: general_publisher,
      pending_push: false,
      lock_selection: false,
      create_price_floor: false,
      filter: {
        items: [{ name: "User", id: -1 }],
        open: false,
        field: null,
        value: null,
        ops: "contains#",
        query: [],
        isTabChange: false,
        pf_name_filter: [],
        operators: [
          { v: "", t: "is equal to" },
          { v: "contains#", t: "contains" },
        ],
      },
    };
  },
};
</script>

<style scoped>
.v-application--wrap {
  min-height: unset;
}

.opacity {
  background-color: #fdfdfd96;
}

.disapear {
  opacity: 0.5;
}

.resize-bar {
  cursor: ew-resize;
  border: 4px solid transparent;
}

.resize-bar:active,
.resize-bar:hover {
  background-color: #666666;
}
</style>
