import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    siteEntities: [],
    media_list: [],
    iabCategoryList: [],
    refresh_lock: false,
    sspNotification: [],
    revision_status: [
      {
        text: "Modified",
        value: "modified",
      },
      {
        text: "Prerelease",
        value: "prerelease",
      },
      {
        text: "Live",
        value: "live",
      },
      {
        text: "Obsoleted",
        value: "obsoleted",
      },
    ],
  },

  getters: {
    getRefreshLock: state => state.refresh_lock,
    getLoading: state => {
      return state.loading;
    },
    getSiteEntities: state => {
      return state.siteEntities;
    },
    getSiteEntityById: state => id => {
      return state.siteEntities.find(el => el.id === id) || {};
    },
    getDefaultSiteEntity: () => () => ({
      id: 0,
      name: "",
      url: "",
      gam_ad_unit_name: "",
      is_external: false,
      publisher_id: null,
      odas_site_id: null,
      odas_site_category_id: null,
      tm_deploy_enabled: true,
    }),
    getMediaList(state) {
      return state.media_list;
    },
    getIabCategoryList: state => {
      return state.iabCategoryList;
    },
    getIabCategoryName: state => idCategory => {
      if (
        idCategory == null ||
        state.iabCategoryList === undefined ||
        state.iabCategoryList == null ||
        !state.iabCategoryList ||
        !(state.iabCategoryList.length > 0)
      ) {
        return "None";
      } else {
        return state.iabCategoryList.find(el => {
          return el.id === idCategory;
        }).name;
      }
    },
    getSSPNotification: state => {
      return state.sspNotification;
    },
    getRevisionStatus: state => {
      return state.revision_status;
    },
  },

  mutations: {
    setRefreshLock: (state, refresh_lock) => {
      state.refresh_lock = refresh_lock;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setSiteEntities(
      state,
      {
        site_list,
        ads_txt_result_list,
        config_status_web,
        config_status_amp,
        site_lock,
      }
    ) {
      const ads_txt_result_by_site_id = {};
      const config_status_web_by_site_id = {};
      const config_status_amp_by_site_id = {};
      const site_lock_by_site_id = {};

      ads_txt_result_list.forEach(element => {
        ads_txt_result_by_site_id[element.site_id] = element;
      });

      config_status_web.forEach(element => {
        config_status_web_by_site_id[element.site_id] = element;
      });

      config_status_amp.forEach(element => {
        config_status_amp_by_site_id[element.site_id] = element;
      });

      site_lock.forEach(element => {
        site_lock_by_site_id[element.site_id] = element;
      });

      state.siteEntities = site_list.map(function(item) {
        if (!item.match_config) item.match_config = { media: [] };
        item.media = item.match_config.media;

        if (ads_txt_result_by_site_id[item.id] !== undefined) {
          item.ads_txt_compare_task_status =
            ads_txt_result_by_site_id[item.id].ads_txt_compare_task_status;
          item.ads_txt_compare_task_message =
            ads_txt_result_by_site_id[item.id].ads_txt_compare_task_message;
          item.ads_txt_compare_task_date =
            ads_txt_result_by_site_id[item.id].ads_txt_compare_task_date;
        } else {
          item.ads_txt_compare_task_status = "";
          item.ads_txt_compare_task_message = "";
          item.ads_txt_compare_task_date = "";
        }

        if (site_lock_by_site_id[item.id] !== undefined) {
          item.lock_by = site_lock_by_site_id[item.id].user_id;
          item.locked_by_sspa = site_lock_by_site_id[item.id].lock_type === 2;
          item.lock_by_user_email =
            site_lock_by_site_id[item.id].lock_by_user_email;
        } else {
          item.lock_by = null;
          item.locked_by_sspa = false;
          item.lock_by_user_email = null;
        }

        item.sc_amp = config_status_amp_by_site_id[item.id] || null;
        item.sc_web = config_status_web_by_site_id[item.id] || null;

        let sc_web_date = null;
        let sc_amp_date = null;

        if (item.sc_web && item.sc_web.updated_on != null) {
          sc_web_date = Date(item.sc_web.updated_on);
        }

        if (item.sc_amp && item.sc_amp.updated_on != null) {
          sc_amp_date = Date(item.sc_amp.updated_on);
        }

        if (sc_web_date == null && sc_amp_date == null) {
          item.sc_latest = { updated_on: "" };
          item.has_config = false;
        } else {
          item.has_config = true;
          if (sc_web_date != null && sc_amp_date == null) {
            item.sc_latest = item.sc_web;
          }
          if (sc_web_date == null && sc_amp_date != null) {
            item.sc_latest = item.sc_amp;
          }
          if (sc_web_date != null && sc_amp_date != null) {
            item.sc_latest =
              sc_web_date >= sc_amp_date ? item.sc_web : item.sc_amp;
          }
        }

        return item;
      });
    },
    setMediaList(state, payload) {
      state.media_list = payload.map(el => el.media);
    },
    setIabCategoryList(state, payload) {
      state.iabCategoryList = payload;
    },
    setSSPNotification(state, payload) {
      state.sspNotification = payload;
    },
  },

  actions: {
    async fetchSiteEntities({ commit, rootGetters }, archived = "") {
      commit("setLoading", true);
      const param = archived
        ? `?show_archived=${archived}&read_only=${rootGetters["tagsManager/isReadOnly"]}`
        : `?read_only=${rootGetters["tagsManager/isReadOnly"]}`;
      const site_request = axios.get("/v2/siteentity" + param);
      const config_status_request_web = axios.get(
        "/v2/siteentity/site_config_status/web" + param
      );
      const config_status_request_amp = axios.get(
        "/v2/siteentity/site_config_status/amp" + param
      );
      const site_lock_request = axios.get("/v2/siteentity/site_locks" + param);
      const ads_txt_result_request = axios.get("/v2/ads_txt/compare_status");
      const [
        site_response,
        ads_txt_result_response,
        config_status_response_web,
        config_status_response_amp,
        site_lock_response,
      ] = await Promise.all([
        site_request,
        ads_txt_result_request,
        config_status_request_web,
        config_status_request_amp,
        site_lock_request,
      ]);
      commit("setSiteEntities", {
        site_list: site_response.data,
        ads_txt_result_list: ads_txt_result_response.data,
        config_status_web: config_status_response_web.data,
        config_status_amp: config_status_response_amp.data,
        site_lock: site_lock_response.data,
      });
      commit("setLoading", false);
    },
    async updateSiteEntities({ commit, dispatch }, siteEntity) {
      siteEntity.match_config.media = siteEntity.media;

      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .put("/siteentity/", siteEntity)
          .then(() => {
            dispatch("fetchSiteEntities");
          })
          .catch(err => {
            if (err.response.status === 409) {
              let msg =
                "msg" in err.response.data && err.response.data.msg
                  ? err.response.data.msg
                  : err.toString();
              commit("warnings/snackbarError", msg, {
                root: true,
              });
              commit("warnings/addWarning", msg, {
                root: true,
              });
            }
            dispatch("fetchSiteEntities");
          });
        resolve();
      });
    },
    async createSiteEntities({ commit, dispatch }, siteEntity) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios
          .post("/siteentity/", siteEntity)
          .then(() => {
            dispatch("fetchSiteEntities");
          })
          .catch(err => {
            if (err.response.status === 409) {
              let msg =
                "msg" in err.response.data && err.response.data.msg
                  ? err.response.data.msg
                  : err.toString();
              commit("warnings/snackbarError", msg, {
                root: true,
              });
              commit("warnings/addWarning", msg, {
                root: true,
              });
            }
            dispatch("fetchSiteEntities");
          });
        resolve();
      });
    },
    async deleteSiteEntity({ commit, dispatch }, siteEntity) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.delete("/siteentity/" + siteEntity.id).then(() => {
          dispatch("fetchSiteEntities");
        });
        resolve();
      });
    },
    async fetchMediaList({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get("/reporting_financial/media_list").then(response => {
          commit("setMediaList", response.data.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async fetchIabCategories({ commit }) {
      commit("setLoading", true);
      const response = await axios.get("/adslot_web_config/iabCategory");
      commit("setIabCategoryList", response.data);
      commit("setLoading", false);
    },
    async refreshLock({ commit, getters, rootGetters }) {
      if (rootGetters["tagsManager/isReadOnly"]) {
        return;
      }

      await commit("setRefreshLock", true);
      const theSite = rootGetters["tagsManager/getSite"];
      if (theSite && theSite.id !== undefined && theSite.id !== null) {
        while (getters.getRefreshLock) {
          await axios.post("/siteentity/" + theSite.id + "/refresh-lock");
          await new Promise(
            r => setTimeout(r, process.env.VUE_APP_SITE_LOCK_INTERVAL * 1000) // ms here, env is in seconds
          );
        }
      }
    },
    async unlockSite({ rootGetters }, site_id) {
      if (rootGetters["tagsManager/isReadOnly"]) {
        return;
      }

      if (site_id != null && site_id !== 0) {
        await axios.post("/siteentity/" + site_id + "/unlock");
      }
    },
    async fetchSSPNotification({ commit }, site_id) {
      return new Promise(resolve => {
        axios.get("/sspa/sync/status/" + site_id).then(response => {
          commit("setSSPNotification", response.data);
          resolve();
        });
      });
    },
  },
};

export default store;
