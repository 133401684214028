import axios from "axios";

const store = {
  namespaced: true,

  state: {
    loading: true,
    demandPartners: [],
  },

  getters: {
    getLoading: state => {
      return state.loading;
    },
    getDemandPartners: state => {
      return state.demandPartners;
    },
    getDefaultDemandPartner: () => () => {
      return {
        id: 0,
        name: "",
        url: "",
        contact_email: "",
        prebidserver: false,
        client_only: false,
        deliver_in_safeframe: true,
      };
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setDemandPartners(state, demandPartners) {
      state.demandPartners = demandPartners;
    },
  },

  actions: {
    async fetchDemandPartners({ commit }) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.get("/dp/").then(response => {
          commit("setDemandPartners", response.data.data);
          commit("setLoading", false);
        });
        resolve();
      });
    },
    async updateDemandPartner({ commit, dispatch }, demandPartner) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.put("/dp/", demandPartner).then(() => {
          dispatch("fetchDemandPartners");
        });
        resolve();
      });
    },
    async createDemandPartner({ commit, dispatch }, demandPartner) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.post("/dp/", demandPartner).then(() => {
          dispatch("fetchDemandPartners");
        });
        resolve();
      });
    },
    async deleteDemandPartner({ commit, dispatch }, demandPartner) {
      commit("setLoading", true);
      return new Promise(resolve => {
        axios.delete("/dp/" + demandPartner.id).then(() => {
          dispatch("fetchDemandPartners");
        });
        resolve();
      });
    },
  },
};

export default store;
