<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        height="calc(100vh - 250px)"
        :loading="getLoading"
        :headers="headers"
        :items="getDemandPartners"
        :search="search"
        item-key="id"
        fixed-header
        :items-per-page="30"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template v-slot:top>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="space-around" class="pb-5">
            <v-col cols="3" class="text-center">
              <v-btn
                color="primary"
                v-on:click="editDemandPartner(getDefaultDemandPartner())"
                >{{ $t("buttons.demand_partners.add") }}
                <v-icon dark right>add_box</v-icon></v-btn
              >
            </v-col>
            <v-divider vertical inset />
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.url="{ item }">
          <v-list-item :href="'//' + item.url" target="_blank">
            {{ item.url }}</v-list-item
          >
        </template>
        <template v-slot:item.action="{ item }">
          <div style="white-space: nowrap">
            <v-btn depressed icon @click="editDemandPartner(item)">
              <v-icon small>
                edit
              </v-icon>
            </v-btn>
            <v-btn depressed icon @click="showDemandPartner(item)">
              <v-icon small>
                visibility
              </v-icon>
            </v-btn>
            <v-btn depressed icon @click="onArchive(item)">
              <v-icon small color="error">
                delete
              </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="80%">
        <v-card style="overflow-x: hidden;">
          <v-row align="center">
            <v-col cols="auto">
              <v-card-title>
                <span v-if="!readOnly && editedIndex !== -1" class="headline"
                  >{{ $t("buttons.demand_partners.edit") }}:</span
                >
                <span
                  v-else-if="readOnly && editedIndex !== -1"
                  class="headline"
                  >{{ $t("buttons.demand_partners.show") }}:</span
                >
                <span v-else class="headline"
                  >{{ $t("buttons.demand_partners.add") }}:</span
                >
              </v-card-title>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                v-if="!readOnly"
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="save"
              >
                {{ $t("buttons.general.save") }}</v-btn
              ><v-btn class="mr-2" color="blue darken-1" text @click="close">{{
                $t("buttons.general.close")
              }}</v-btn>
            </v-col>
          </v-row>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.name"
                      label="Name"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.url"
                      label="URL"
                      :rules="createRules([baseValidation.domain])"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model.number="editedItem.contact_email"
                      label="Contact Email"
                      :rules="createRules([baseValidation.email])"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="6" md="4">
                    <v-select
                      :readonly="readOnly"
                      :filled="readOnly"
                      item-text="name"
                      item-value="id"
                      :items="getPaymentTerms"
                      v-model="editedItem.payment_term_id"
                      label="Payment Term"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="6" md="4">
                    <v-switch
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.prebidserver"
                      label="PrebidServer enabled"
                      @change="() => (editedItem.client_only = false)"
                    />
                  </v-col>
                  <v-col cols="3" sm="6" md="4">
                    <v-switch
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.client_only"
                      label="Client only"
                      @change="() => (editedItem.prebidserver = false)"
                    />
                  </v-col>
                  <v-col cols="3" sm="6" md="4">
                    <v-switch
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="editedItem.deliver_in_safeframe"
                      label="Deliver in SafeFrame"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!readOnly"
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="save"
            >
              {{ $t("buttons.general.save") }}</v-btn
            ><v-btn class="mr-2" color="blue darken-1" text @click="close">{{
              $t("buttons.general.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ConfirmationDialog
        :confirmation-dialog="confirmationDialog"
        :on-confirm="confirmArchive"
        :on-close="closeArchive"
        msg="Do you want to archive DP?"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { baseValidation } from "../../../validation/baseValidation";
import { validationHelper } from "../../../validation/validationHelper";
import ConfirmationDialog from "@/components/Helpers/ConfirmationDialog";

export default {
  name: "DemandPartnersTable",
  components: { ConfirmationDialog },
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      dialog: false,
      confirmationDialog: false,
      readOnly: false,
      valid: true,
      editedIndex: -1,
      editedItem: {},
      itemToRemove: {},
      search: "",
      filterList: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "URL",
          value: "url",
        },
        {
          text: "Contact Email",
          value: "contact_email",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          filterable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.fetchDemandPartners();
    this.fetchPaymentTerms();
  },
  computed: {
    ...mapGetters("demandPartners", [
      "getLoading",
      "getDemandPartners",
      "getDefaultDemandPartner",
    ]),
    ...mapGetters("paymentTerm", ["getLoading", "getPaymentTerms"]),
  },
  methods: {
    ...mapActions("demandPartners", [
      "fetchDemandPartners",
      "createDemandPartner",
      "updateDemandPartner",
      "deleteDemandPartner",
    ]),
    ...mapActions("paymentTerm", ["fetchPaymentTerms"]),
    editDemandPartner(item) {
      this.editedIndex = this.getDemandPartners.indexOf(item);
      this.editedItem = { ...item };
      this.readOnly = false;
      this.dialog = true;
    },
    showDemandPartner(item) {
      this.editedIndex = this.getDemandPartners.indexOf(item);
      this.editedItem = { ...item };
      this.readOnly = true;
      this.dialog = true;
    },
    onArchive(item) {
      this.itemToRemove = item;
      this.confirmationDialog = true;
    },
    confirmArchive() {
      this.deleteDemandPartner(this.itemToRemove);
      this.confirmationDialog = false;
    },
    closeArchive() {
      this.confirmationDialog = false;
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateDemandPartner(this.editedItem);
        } else {
          this.createDemandPartner(this.editedItem);
        }
        this.close();
      }
    },
  },
};
</script>

<style scoped></style>
