var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.conflict.status,"max-width":"800"},on:{"input":_vm.handleClose}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Conflict Resolution ")]),_c('v-card-text',[_c('p',[_vm._v("Select which version you want to keep for each changed field:")]),(!_vm.isAllSelected)?_c('p',{staticClass:"warning--text mt-4"},[_vm._v(" Please select a version for all changed fields ")]):_vm._e(),(_vm.diffData)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Field")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Changes")])])]),_c('tbody',_vm._l((_vm.diffData),function(diff,field,index){return _c('tr',{key:field,class:{
                  'grey lighten-3': index % 2 === 0,
                }},[_c('td',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t(`floor_manager.label.dimension.${field.toLowerCase()}`))+" ")]),_c('td',{staticClass:"value-cell",class:_vm.getValueClass(field, 'server'),on:{"click":function($event){return _vm.selectVersion(field, 'server')}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-radio',{staticClass:"mr-2",attrs:{"off-icon":_vm.selectedVersions[field] === 'server'
                          ? '$radioOn'
                          : '$radioOff',"readonly":""}}),_c('span',[_vm._v(_vm._s(_vm.formatValue({ value: diff.server, field, type: diff.type, })))])],1),(index === 0)?_c('div',{staticClass:"version-label"},[_vm._v(" Server Version ")]):_vm._e()]),_c('td',{staticClass:"value-cell",class:_vm.getValueClass(field, 'local'),on:{"click":function($event){return _vm.selectVersion(field, 'local')}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-radio',{staticClass:"mr-2",attrs:{"off-icon":_vm.selectedVersions[field] === 'local'
                          ? '$radioOn'
                          : '$radioOff',"readonly":""}}),_c('span',[_vm._v(_vm._s(_vm.formatValue({ value: diff.local, field, type: diff.type })))])],1),(index === 0)?_c('div',{staticClass:"version-label"},[_vm._v(" Your Version ")]):_vm._e()])])}),0)]},proxy:true}],null,false,1620703554)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.handleClose}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":!_vm.isAllSelected},on:{"click":_vm.handleMerge}},[_vm._v(" Apply Selected Changes ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }