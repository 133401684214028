<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" app permanent>
    <v-list-item class="px-2">
      <img
        src="../../public/logo-optidigital_versionhorizontal_fullcolor-1.png"
        alt="optidigital-logo"
        class="logo"
        v-if="!mini"
      />
      <img
        src="../../public/logo.png"
        alt="optidigital-logo-mini"
        class="logo-mini"
        :style="mini ? 'display: block;' : 'display:none'"
      />
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider />

    <v-list dense>
      <v-list-item
        v-for="item in nav_items_wo_children"
        :key="item.title"
        :to="item.path"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('nav_menu.' + item.title)" />
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-for="item in nav_items_with_children"
        :key="item.title"
        class="px-0"
        :prepend-icon="item.icon"
        no-action
        link
        :to="item.path"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="$t('nav_menu.' + item.title)" />
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="sub_item in item.sub_items"
          :key="sub_item.title"
          link
          :to="sub_item.path"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t('nav_menu.' + sub_item.title)" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <v-list-item key="extern-view" v-if="false">
        <v-list-item-content>
          <v-row justify="center">
            <v-switch
              label="new UI"
              v-model="view_extern"
              inset
              dense
              @click="setViewExtern({ value: view_extern })"
            />
            <v-icon small>mdi-creation</v-icon>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        key="connect-as"
        class="px-2"
        v-if="!mini && isEligibleForDemo"
      >
        <v-list-item-content>
          <v-btn text color="primary" @click="connect">
            Connect as Demo
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="profile" class="px-2">
        <v-list-item-avatar class="py-5">
          <v-img :src="getUserProfileImg" />
        </v-list-item-avatar>
        <v-list-item-title>{{ getUserProfile.google_name }}</v-list-item-title>
      </v-list-item>
      <v-list-item key="logout" to="/" @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('nav_menu.logout')" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import store from "../store";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "LeftSideMenu",
  data() {
    return {
      drawer: true,
      mini: true,
      nav_items_wo_children: {},
      nav_items_with_children: {},
      view_extern: false,
    };
  },
  created() {
    store.dispatch("context/fetchContext");
    store
      .dispatch("context/fetchNavMenu")
      .then(
        () =>
          (this.nav_items_wo_children = this.getNavMenuRoutes?.filter(
            el => !el.sub_items
          ))
      )
      .then(
        () =>
          (this.nav_items_with_children = this.getNavMenuRoutes?.filter(
            el => el.sub_items != null
          ))
      );
  },
  mounted() {
    store.dispatch("context/fetchContext");
    this.view_extern = this.getViewExtern;
    // little hack to print the icon in b64
    const icon = document.querySelector(".logo-mini").getAttribute("src");
    document.querySelector("link[rel=icon]").setAttribute("href", icon);
  },
  computed: {
    ...mapGetters("context", [
      "getNavMenuRoutes",
      "getUserProfile",
      "getUserProfileImg",
      "getViewExtern",
    ]),
    isEligibleForDemo() {
      return [
        "olly@optidigital.fr",
        "laure@optidigital.fr",
        "jean-cesar@optidigital.fr",
        "nadia@optidigital.fr",
        "emmanuel@optidigital.fr",
        "florian@optidigital.fr",
        "sebastien@optidigital.fr",
        "magali@optidigital.fr",
        "juan-pablo@optidigital.fr",
        "ivana@optidigital.fr",
        "stephen@optidigital.fr",
      ].includes(this.getUserProfile.email);
    },
  },
  methods: {
    ...mapMutations("context", ["setViewExtern"]),
    ...mapActions("context", ["fetchNavMenu", "fetchContext", "connectAs"]),
    ...mapActions("warnings", ["openDialog"]),
    async connect() {
      const response = await this.connectAs({ user_id: 78 });
      if (response) {
        await this.$router.push({ name: "Home" });
      }
    },
    logout() {
      this.$store
        .dispatch("context/logout")
        .then()
        .catch(err => window.console.log(err));
    },
  },
};
</script>
<style scoped>
.logo {
  width: 85%;
  max-width: 200px;
  min-width: 180px;
  animation: fadeon 0.5s linear forwards;
}

.logo-mini {
  max-width: 40px;
  animation: animatemini 0.5s linear forwards;
}

@keyframes fadeon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes animatemini {
  0% {
    opacity: 0;
    transform: rotate(220deg);
  }
  100% {
    opacity: 100;
    transform: rotate(0deg);
  }
}
.v-icon {
  margin-left: 2px;
}
</style>
