<template>
  <LoadingOverlay :overlay="loading">
    <template v-slot:default>
      <v-row class="rounded countryContainerBorders ma-0" style="width: 100%;">
        <v-col
          cols="6"
          class="pa-0 countryContainerBorderRight"
          style="width: 100%;"
        >
          <v-container
            class="countryContainerBorderBottom countryContainerRowHeight"
            style="width: 100%;"
          >
            <v-text-field
              flat
              solo
              type="text"
              class="ma-0 pa-0"
              hide-details
              append-icon="mdi-magnify"
              label="Search"
              v-model="search"
            />
          </v-container>
          <v-container
            class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
            style="width: 100%;"
          >
            <v-row>
              <v-col>
                <v-card-title
                  v-if="countriesRegistry.length !== 0 && !loading"
                  class="font-weight-bold text-body-2"
                  >{{ searchCountry.length }} items</v-card-title
                >
                <v-card-title v-else class="font-weight-bold text-body-2">
                  No items
                </v-card-title>
              </v-col>
              <v-col align-self="center" align="right">
                <v-btn
                  v-model="countriesRegistry"
                  class="text-capitalize"
                  color="primary"
                  plain
                  @click="includeAllCountries"
                >
                  <v-icon small>mdi-check</v-icon>
                  Include All
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            class="pt-1 pb-1 overflow-x-hidden"
            style="height: 376px;"
          >
            <v-list flat v-if="countriesRegistry.length !== 0 && !loading">
              <v-list-item-group>
                <v-list-item
                  v-for="item in searchCountry"
                  v-bind:key="item.id"
                  v-bind:title="item.name"
                  class="pb-0 text-body-2 font-weight-regular"
                  @click="addCountry(item)"
                >
                  <v-icon small v-if="item.name in (value ?? [])"
                    >mdi-check-circle</v-icon
                  >
                  {{ capitalize(item.name) }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-container>
        </v-col>

        <v-col cols="6" class="pa-0" style=" width: 100%;">
          <v-container
            class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
            style="width: 100%;"
          >
            <v-row>
              <v-col cols="6">
                <v-card-title
                  v-if="value?.length > 0 && !loading"
                  class="font-weight-bold text-body-2"
                >
                  {{ value.length }}
                  items
                </v-card-title>
                <v-card-title v-else class="font-weight-bold text-body-2"
                  >None selected</v-card-title
                >
              </v-col>
              <v-col cols="6" class="pt-5" align="right">
                <v-btn
                  class="text-capitalize"
                  color="black"
                  plain
                  @click="deleteAllCountries"
                >
                  Clear All
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            class="pt-1 pb-1 overflow-x-hidden"
            style="height: 442px;"
          >
            <v-list class="pt-1 pb-1" flat>
              <v-list-item-group
                v-for="(item, index) in orderPublisherCountries"
                v-bind:key="item.name"
              >
                <v-list-item
                  v-if="value.length > 0 && !loading"
                  v-bind:key="index"
                  v-bind:title="item.name"
                  class="pb-0 text-body-2 font-weight-regular"
                  @click="removeCountry(index)"
                  return
                  obj
                >
                  {{ capitalize(item.name) }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-container>
        </v-col>
      </v-row>
    </template>
  </LoadingOverlay>
</template>
<script>
import LoadingOverlay from "@/components/Helpers/LoadingOverlay.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CountrySelector",
  components: { LoadingOverlay },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["cs-update_selection"],
  data() {
    return { search: "" };
  },
  mounted() {
    this.value ??= [];
    if (this.value.length === this.countriesRegistry?.length) {
      this.value.splice(0, this.value.length);
    }
  },
  computed: {
    ...mapGetters("countrySelector", ["getLocalSelection"]),
    ...mapGetters("publishers", ["getAllCountries"]),
    orderPublisherCountries() {
      if (this.value) {
        this.orderCountries(this.value);
      }
      return this.value;
    },
    searchCountry() {
      this.orderCountries(this.countriesRegistry);
      return this.countriesRegistry.filter(country => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    value: {
      get() {
        return this.getLocalSelection;
      },
      set(input) {
        this.setLocalSelection = input;
        this.$emit("cs-update_selection", input);
      },
    },
    countriesRegistry: {
      get() {
        const avoid_cc = this.value.map(({ country_code }) => country_code);
        return this.getAllCountries.filter(
          ({ country_code }) => !avoid_cc.includes(country_code)
        );
      },
      set() {
        /* do nothing */
      },
    },
  },
  methods: {
    ...mapMutations("publishers", ["setAllCountries"]),
    ...mapMutations("countrySelector", ["setLocalSelection"]),
    orderCountries(countries_list) {
      return countries_list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },
    async deleteAllCountries() {
      if (this.value?.length > 0) {
        this.value.splice(0, this.value.length);
      }
    },
    includeAllCountries() {
      this.value.push(...this.countriesRegistry);
    },
    addCountry(item) {
      this.value ??= [];

      this.value.push({
        name: this.formatCountryName(item.name),
        gam_id: item.gam_id,
        country_code: item.country_code,
      });

      this.search = "";
    },
    removeCountry(index) {
      this.value.splice(index, 1);
    },
    capitalize(str) {
      return str?.toUpperCase?.();
    },
    formatCountryName([firstLetter, ...tail]) {
      return [
        firstLetter.toUpperCase(),
        ...tail.map(w => w.toLowerCase()),
      ].join("");
    },
  },
};
</script>
<style scoped>
.countryContainerBorders {
  border: 1px solid #bcbcbc;
}
.countryContainerBorderRight {
  border-right: 1px solid #bcbcbc;
}
.countryContainerBorderBottom {
  border-bottom: 1px solid #bcbcbc;
}
.countryContainerRowHeight {
  max-height: 65px;
}
</style>
