<template>
  <v-simple-table fixed-header height="63vh">
    <thead>
      <tr>
        <th @click="sortBy(status_sort, { name: 'status' })" class="clickable">
          {{ $t("floor_manager.label.pf.status") }}
          <v-icon
            small
            v-if="
              price_sort.method_name === 'status' && price_sort.desc !== null
            "
          >
            {{ price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down" }}
          </v-icon>
        </th>
        <th @click="sortBy(name_sort, { name: 'name' })" class="clickable">
          <IconTooltip
            :content="$t('floor_manager.label.pf.name')"
            :description="$t('floor_manager.help.pf.name')"
          />
          <v-icon
            small
            v-if="price_sort.method_name === 'name' && price_sort.desc !== null"
          >
            {{ price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down" }}
          </v-icon>
        </th>
        <th @click="sortBy(floor_sort, { name: 'floor' })" class="clickable">
          <IconTooltip
            :content="$t('floor_manager.label.pf.value')"
            :description="$t('floor_manager.help.pf.value')"
          />
          <v-icon
            small
            v-if="
              price_sort.method_name === 'floor' && price_sort.desc !== null
            "
          >
            {{ price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down" }}
          </v-icon>
        </th>
        <th @click="sortBy(target_sort)" class="clickable">
          <IconTooltip
            :content="$t('floor_manager.label.pf.target')"
            :description="$t('floor_manager.help.pf.target')"
          />
          <v-icon
            small
            v-if="
              price_sort.method_name === 'target' && price_sort.desc !== null
            "
          >
            {{ price_sort.desc ? "mdi-arrow-up" : "mdi-arrow-down" }}
          </v-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="pf in prepared_price_floor"
        :key="pf.id"
        @click="() => setSelection(pf)"
        :class="pf.id === active ? 'clicked_active' : ''"
      >
        <td>
          <v-switch
            v-model="pf.status"
            :disabled="!isAbleToEdit"
            @click="changeStatus(pf)"
          />
        </td>
        <td>
          <a class="rule-name" @click.prevent href>{{
            priceFloorNameHandler(pf)
          }}</a>
        </td>
        <td>
          {{
            (+pf.floor).toLocaleString("fr", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
          EUR
        </td>
        <td>
          <v-menu open-on-hover offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon
                  :color="gradiantDimension(configured_dimensions(pf))"
                  @click.prevent
                >
                  mdi-target
                </v-icon>
              </v-btn>
            </template>
            <v-card class="pa-0">
              <v-list>
                <v-list-item
                  v-for="(dim, i) in configured_dimensions(pf)"
                  :key="i"
                >
                  {{ dim.name }} = {{ targetValueHandler(dim) }}
                </v-list-item>
                <v-list-item v-if="configured_dimensions(pf).length === 0">
                  {{ $t("floor_manager.label.no_dimension") }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "@/main";
import IconTooltip from "@/components/Extern/Helper/IconTooltip.vue";

export default {
  name: "FloorTable",
  components: { IconTooltip },
  props: {
    price_floor: {
      type: Array,
    },
    name_filter: {
      type: Array,
    },
    isAbleToEdit: {
      type: Boolean,
    },
  },
  emits: ["select_price_floor"],
  data() {
    return {
      active: null,
      price_sorting_method: this.target_sort,
      price_sort: {
        method: this.target_sort,
        method_name: "target",
        desc: null,
      },
    };
  },
  mounted() {
    eventBus.$on("cancel_select_price_floor", () => {
      this.cancelSelection();
    });
  },
  computed: {
    prepared_price_floor() {
      return this.priceFloorFiltration().sort(this.price_sort.method);
    },
  },
  methods: {
    ...mapActions("floorRules", ["changeStatus"]),
    sortBy(method, { name = "target" } = {}) {
      if (this.price_sort.method_name !== name) {
        this.price_sort.desc = null;
        this.price_sort.method_name = name;
      }
      if (this.price_sort.desc === false) {
        this.price_sort.desc = null;
        this.price_sort.method = this.target_sort;
        this.method_name = "target";
        return;
      }

      this.price_sort.desc =
        this.price_sort.desc === null ? true : !this.price_sort.desc;
      this.price_sort.method = !this.price_sort.desc
        ? (a, b) => method(b, a)
        : method;
    },
    target_sort(a, b) {
      if (a.status !== b.status) {
        return b.status - a.status;
      }
      if (a.weight !== b.weight) {
        return a.weight - b.weight;
      }
      if (a.std_deviation !== b.std_deviation) {
        return a.std_deviation - b.std_deviation;
      }

      return b.floor - a.floor;
    },
    name_sort(a, b) {
      return a.name.localeCompare(b.name);
    },
    status_sort(a, b) {
      return b.status - a.status;
    },
    floor_sort(a, b) {
      return b.floor - a.floor;
    },
    configured_dimensions({ dimensions }) {
      return dimensions.filter(({ value }) => {
        return value && value !== "" && value?.length > 0;
      });
    },
    cancelSelection() {
      this.active = null;
      this.$emit("select_price_floor", null);
    },
    setSelection(item) {
      this.active = item.id;
      this.$emit("select_price_floor", item);
    },
    priceFloorFiltration() {
      this.price_floor ??= [];
      if (this.name_filter && this.name_filter.length > 0) {
        return this.price_floor.filter(({ name }) =>
          this.name_filter.some(fname =>
            name.toLowerCase().includes(fname.toLowerCase())
          )
        );
      }
      return this.price_floor;
    },
    priceFloorNameHandler({ name }) {
      return name;
    },
    targetValueHandler({ value, type }) {
      if (type.component === "CountrySelector") {
        return value?.length < 15
          ? value.map(({ name }) => name).join(", ")
          : `${value.length} selected countries`;
      }

      const items = type.options?.["items"];
      if (items && typeof items !== "string") {
        return type.options.items
          .filter(item => value.includes(item.v))
          .map(item => item.t)
          .join(", ");
      }

      if (Array.isArray(value)) {
        return value.join(", ");
      }
      return value;
    },
    gradiantDimension(dimensions) {
      if (dimensions.length > 0) {
        return "primary";
      }
      return "orange";
    },
  },
};
</script>

<style scoped>
.clicked_active {
  background-color: #73d679aa !important;
  border-radius: 15%;
}

.rule-name {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.clickable:hover {
  opacity: 0.7;
}
</style>
