<template>
  <v-card min-height="50vh" class="scroll d-flex flex-column">
    <v-overlay absolute :opacity="0.4" v-model="pending_save">
      <v-progress-circular size="64" indeterminate color="primary" />
    </v-overlay>
    <v-card-title>
      <v-row>
        <v-col>
          {{ $t("floor_manager.title.settings") }}
        </v-col>
        <v-col align="right">
          <v-btn icon @click="onClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <span v-if="edit_pf.updated_by">
        {{ $t("floor_manager.label.last_update", edit_pf) }}
      </span>
      <v-form
        v-if="dimensions"
        ref="dimension_card_form"
        v-model="valid"
        lazy-validation
      >
        <v-row class="ma-1">
          <v-col>
            <v-text-field
              :disabled="!isAbleToEdit"
              outlined
              dense
              hide-details="auto"
              v-model="edit_pf.name"
              :rules="[
                v => (v ? true : 'Cannot save an empty name'),
                v =>
                  v?.length < 255
                    ? true
                    : 'Rule name support only 255 characters',
              ]"
            >
              <template v-slot:label>
                <IconTooltip
                  :content="$t('floor_manager.label.pf.name')"
                  :description="$t('floor_manager.help.pf.name')"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              ref="price_floor_input"
              :disabled="!isAbleToEdit"
              outlined
              dense
              hide-details="auto"
              label="Price floor"
              :type="price_floor_focus ? 'number' : 'number'"
              @focus="price_floor_focus = true"
              @blur="price_floor_focus = false"
              v-model="price_floor"
              :rules="[
                v => (v ? true : 'Please fill a price floor before saving.'),
                v => (v > 0 ? true : 'A price floor should be greater than 0'),
              ]"
            >
              <template v-slot:label>
                <IconTooltip
                  :content="$t('floor_manager.label.pf.value')"
                  :description="$t('floor_manager.help.pf.value')"
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-expansion-panels :disabled="!isAbleToEdit">
            <v-expansion-panel
              v-for="dimension in activeDimension"
              :key="dimension.name"
            >
              <v-expansion-panel-header>
                <v-row justify="space-between">
                  <v-col>
                    <strong>
                      <IconTooltip
                        :content="
                          $t(
                            `floor_manager.label.dimension.${dimension.name.toLowerCase()}`
                          )
                        "
                        :description="
                          $t(
                            `floor_manager.help.dimension.${dimension.name.toLowerCase()}`
                          )
                        "
                      />
                    </strong>
                  </v-col>
                  <v-col>
                    <strong>{{ customLabel(dimension) }}</strong>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <component
                      v-bind:is="
                        customElement(dimension.type?.component, { dimension })
                      "
                      outlined
                      v-model="dimension.value"
                      v-bind="
                        customOptions(dimension.type?.options, { dimension })
                      "
                      ref="dimension"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <span v-if="dimension.updated_by">
                    {{ $t("floor_manager.label.last_update", edit_pf) }}
                  </span>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-form>

      <div v-else>{{ $t("floor_manager.label.no_select") }}</div>
    </v-card-text>
    <v-footer class="pa-2" v-if="isAbleToEdit">
      <v-btn
        v-on:click="onSave"
        :disabled="!dimensions"
        color="primary"
        text
        :loading="pending_save"
      >
        Save
      </v-btn>
      <v-btn v-if="onCancel" @click="onCancel" color="error" text>
        Cancel
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>
import {
  VCombobox,
  VTextField,
  VSelect,
  VAutocomplete,
} from "vuetify/lib/components";
import CountrySelector from "@/components/Extern/Helper/CountrySelector.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/main";
import store from "@/store";
import LoadingOverlay from "@/components/Helpers/LoadingOverlay.vue";
import IconTooltip from "@/components/Extern/Helper/IconTooltip.vue";

export default {
  name: "DimensionCard",
  components: { IconTooltip, LoadingOverlay, CountrySelector },
  props: {
    dimensions: {
      type: Array,
    },
    onCancel: {
      type: Function,
    },
    onPostSave: {
      type: Function,
    },
    isAbleToEdit: {
      type: Boolean,
    },
  },
  watch: {
    dimensions: {
      handler(newVal) {
        if (!newVal) {
          return;
        }
        const dimensions_registry = this.getDimensions;
        if (newVal.length === dimensions_registry.length) {
          return;
        }
        dimensions_registry.sort((a, b) => b.priority - a.priority);
        for (const dimension of dimensions_registry) {
          if (newVal.some(({ name }) => dimension.name === name)) {
            continue;
          }
          this.addDimensionToPriceFloor(dimension);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selectedDimensionToAdd: null,
      editMode: false,
      default_input: VTextField,
      pending_save: false,
      valid: true,
      price_floor_focus: false,
      dimension_handler: {
        [CountrySelector.name]: this.countrySelectorHandler,
        ["v-text-field"]: () => VTextField,
        ["v-select"]: () => VSelect,
        ["v-combobox"]: () => VCombobox,
        ["v-autocomplete"]: () => VAutocomplete,
      },
      dynamicItems: {},
      loadingItems: {},
    };
  },
  computed: {
    ...mapGetters("floorRules", ["getDimensions", "getEditPriceFloor"]),
    activeDimension() {
      return this.dimensions?.filter(d => d.soft_delete === false) ?? [];
    },
    edit_pf: {
      get() {
        if (!this.getEditPriceFloor) {
          return {};
        }
        return this.getEditPriceFloor;
      },
      set(value) {
        this.setEditPriceFloor(value);
      },
    },
    price_floor: {
      get() {
        return this.price_floor_focus
          ? this.edit_pf.floor
          : (+this.edit_pf.floor)
              .toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .replace(",", ".");
      },
      set(value) {
        this.edit_pf.floor = value;
      },
    },
  },
  methods: {
    ...mapMutations("floorRules", ["setEditPriceFloor"]),
    ...mapActions("floorRules", [
      "removeDimensionToPriceFloor",
      "addDimensionToPriceFloor",
      "saveEditFloorRules",
    ]),
    customElement(value, { dimension }) {
      const handler = this.dimension_handler[value] ?? (() => VTextField);
      return handler(dimension);
    },
    async fetchReferenceItems({ items }) {
      const referenceName = items.substring(1);
      try {
        this.$set(this.loadingItems, referenceName, true);
        const data = await this.$store.dispatch(
          "floorRules/fetchReference",
          referenceName
        );
        this.$set(this.dynamicItems, referenceName, data);
      } catch (error) {
        this.$set(this.dynamicItems, referenceName, []);
      } finally {
        this.$set(this.loadingItems, referenceName, false);
      }
    },
    handleReferenceItems(items) {
      const referenceName = items.substring(1);
      if (this.dynamicItems[referenceName]) {
        return this.dynamicItems[referenceName];
      }

      this.fetchReferenceItems({ items });
      return [];
    },
    customOptions(options) {
      if (!options) return {};

      const result = { ...options };
      const items = options.items;
      if (!items || typeof items !== "string" || !items.startsWith("$")) {
        result.loading = false;
        return result;
      }

      const referenceName = items.substring(1);
      result.items = this.handleReferenceItems(items);
      result.loading = this.loadingItems[referenceName] || false;
      return result;
    },
    countrySelectorHandler(dimension) {
      store.commit("countrySelector/setLocalSelection", dimension.value);
      eventBus.$on("cs-update_selection", input => {
        dimension.value = input;
      });

      return CountrySelector;
    },
    customLabel({ value, type }) {
      if (type?.component === CountrySelector.name) {
        return value?.length < 15
          ? value.map(({ name }) => name).join(", ")
          : `${value.length} selected countries`;
      }
      const items = type.options?.["items"];
      if (items && typeof items !== "string") {
        return type.options.items
          .filter(item => value.includes(item.v))
          .map(item => item.t)
          .join(", ");
      }

      if (Array.isArray(value)) {
        return value.join(", ");
      }
      return value;
    },
    async onSave() {
      if (!this.$refs.dimension_card_form?.validate?.()) {
        return;
      }

      this.pending_save = true;
      this.$refs.dimension?.forEach?.(el => el?.blur?.());
      await this.$nextTick();

      await this.saveEditFloorRules();
      this.pending_save = false;
      this.onClose();
    },
    onClose() {
      if (this.onPostSave) {
        this.onPostSave();
      }
      eventBus.$emit("cancel_select_price_floor");
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.v-footer {
  border-top: #c1bfbf 1px solid;
}
</style>
